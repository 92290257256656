<template>
    <div class="arrive">
        <el-row>
            <div class="left-component">
                <el-row>
                    <el-image class="position-left-top" :src="require('../../assets/img/logo_title.png')" />
                </el-row>
                <el-image v-if=showNextStation :src="require('../../assets/img/station_h/' + this.staitonIndex + '.png')" />
            </div>
            <div class="mid-component">
                <div v-if="showCarriageInfo === 1">
                    <el-row>
                        <el-image :src="require('../../assets/img/station_i/' + this.staitonIndex + '.png')" />
                    </el-row>
                    <el-row>
                        <div v-for="num in 6" :key="num">
                            <CoolCarriageVue :carriageIndex=num />
                        </div>
                    </el-row>
                </div>
                <div v-if="showCarriageInfo === 2">
                    aaadddd
                </div>
            </div>
            <div class="right-component">
                <div class="terminal">
                    <el-image 
                        style="width: 256px; height: 52px; margin-left: 100px" 
                        :src="require('../../assets/img/station_d/station_d_' + this.terminalStationIndex  + '.png')"
                    />
                </div>
                <div class="open_door">
                    <div class="red_door">
                        <el-image style="margin-top: 20px;" :src="require('../../assets/img/error.png')" />
                    </div>
                        <el-image :src="require('../../assets/img/open_other.png')" />
                </div>
            </div>
        </el-row>
    </div>
</template>

<script>
import CoolCarriageVue from '@/components/CoolCarriage.vue';
export default {
    components: {CoolCarriageVue},
    data() {
        return {
            staitonIndex: 12,
            terminalStationIndex: 22,
            showNextStation: false,
            showCarriageInfo: 1,    // 1-显示出引导信息，2-显示下一站信息
        };
    },
}
</script>

<style scoped>
    .arrive {
        background-image: url("../../assets/img/bg_main.png");
        width: 99.1%;
        height: 98.2%;
        position: absolute;
        background-size: 100% 100%;
    }
    .position-left-top {
        margin-left: 77px;
        margin-top: 9.39px;
        margin-bottom: 62.75px;
    }
    .arrive-station {
        background-image: url("../../assets/img/station_h/12.png");
        width: 396px;
        height: 184px;
    }
    .right-component {
        display: grid; 
        justify-items: center;
    }
    .terminal {
        background-image: url("../../assets/img/terminal_bg.png");
        width: 428px;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .open_door {
        background-image: url("../../assets/img/door_bg.png");
        width: 405px;
        height: 171px;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .red_door {
        background-image: url("../../assets/img/door_red.png");
        width:  124px;
        height: 124px;
        margin-right: 30px;
    }
</style>
