<template>
    <div class="welcome"></div>
</template>

<stript>
export default {
    name: 'WelcomeFoshan',
}
</stript>

<style lang="less">
    .welcome{
        background-image: url("../../assets/img/welcome.png");

        width: 99%;
        height: 98%;
        position: absolute;
        background-size: 100% 100%;
    }
</style>
