import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import TelvisionShow from "./view/television/TelevisionShow.vue"
import WelcomeFoshan from "./view/welcome/WelcomeFoshan.vue"
import MapShow from "./view/map/MapShow.vue"
import ArriveStation from './view/arrivestation/ArriveStation.vue'

const routes = [
    { path: '/', redirect: '/arrive'},
    { path: '/welcome', component: WelcomeFoshan},
    { path: '/telvision', component: TelvisionShow },
    { path: '/map', component: MapShow },
    { path: '/arrive', component: ArriveStation},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes, // `routes: routes` 的缩写
})
const app = createApp(App)
app.use(ElementPlus)
// 整个应用支持路由。
app.use(router)
app.mount('#app')

// yarn serve