<template>
    <div :style="{'backgroundImage': 'url(' + require(`../assets/img/train/bg/${carrigeName}.png`) + ')'}">
        <el-row class="carriage" >
            <el-image v-if=!this.numRight :src="require('../assets/img/train/num/' + this.carriageIndex + '.png')" style="width: 20px; height: 36px;"/>
            <el-image :src="require('../assets/img/train/lettle_cool.png')" style="width: 60px; height: 28px;"/>
            <el-image v-if=this.numRight :src="require('../assets/img/train/num/' + this.carriageIndex + '.png')" style="width: 20px; height: 36px;"/>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            carrigeName: 'mid',
            numRight: false,    // 数据显示在右边
        };
    },
    props: ['carriageIndex'],
    created() {
        if(this.carriageIndex == 1) {
            this.carrigeName = 'left'
        }else if(this.carriageIndex == 6) {
            this.carrigeName = 'right'
            this.numRight = true
        }
    }
}
</script>

<style scoped>
    .carriage {
        width: 137px; 
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>