<template>
    <div class="map">
        <el-row>
            <el-image 
                class="position-left-top"
                :src="require('../../assets/img/logo_title.png')"
            />
        </el-row>
        <el-row>
            <div v-for="num in 22" :key="num">
                <SubwayStation :stationIndex=num :arrowShow="num<22" :guangZhou7LineShow="num==12 || num==18 || num==19"/>
            </div>
        </el-row>
    </div>
</template>

<script>
import SubwayStation from "../../components/SubwayStation.vue";
export default {
    // 注册
    components: {SubwayStation},
    name: 'MapShow',
    data(){
        return{
            
        }
    }
}
</script>

<style scoped>
    .map {
        background-image: url("../../assets/img/bg_main.png");
        width: 99.1%;
        height: 98.2%;
        position: absolute;
        background-size: 100% 100%;
    }
    .position-left-top {
        margin-left: 77px;
        margin-top: 9.39px;
        margin-bottom: 62.75px;
    }
</style>
