<template>
    <div style="display: flex; align-items: flex-start;">
        <div 
            v-bind:style="needMoveTop ? 
            {display: 'grid', justifyItems: 'center', marginTop: '-45px'} : {display: 'grid', justifyItems: 'center'}"
        >
            <div v-if=this.guangZhou7LineShow style="display: grid; justify-items: center;">
                <el-image
                    :src="require('../assets/img/tran_normal/' + this.stationIndex + '.png')"
                />
                <el-image
                    :src="require('../assets/img/' + this.spaceIconName + '.png')"
                />
            </div>
            <el-row>
                <el-image 
                    :src="require('../assets/img/littleblue/littleblue_'+ this.stationIndex + '.png')"
                />
            </el-row>  
            <el-row>
                <el-image 
                    :src="require('../assets/img/station_c/station_c_'+ this.stationIndex + '.png')"
                />
            </el-row>
        </div>
        <el-image 
            v-if= this.arrowShow
            style="margin-top: 8px; "
            :src="require('../assets/img/arrow_white.png')"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            needMoveTop: false,
            spaceIconName: '',
        };
    },
    props: ['stationIndex', 'arrowShow', 'guangZhou7LineShow'],
    created() {
        if(this.guangZhou7LineShow) {
            this.needMoveTop = true
            if(this.stationIndex == 19) {
                this.spaceIconName = 'littlecycle_red'
            }else {
                this.spaceIconName = 'littlecycle_green'
            }
        }
    }
}
</script>
