<template>
    <div class="television">
      <h1>佛山电视</h1>
    </div>
</template>

<script>
export default {
  name: 'TelevisionShow'
}
</script>

<style scoped>

</style>